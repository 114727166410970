<template>
  <div class="home">
    <h1>Input Refund</h1>

    <div class="content">
      <div class="table-group">
        <div>
          <form
            class="table-inline"
            @submit.prevent="save()"
            :class="{ saving: loadingSave }"
          >
            <table>
              <tr>
                <td>No Daftar</td>
                <td>:</td>
                <td style="position: relative">
                  <input
                    type="text"
                    v-model="noDaftar"
                    @keydown.enter.prevent="handleKeyUp"
                    placeholder="Cari siswa berdasarkan no daftar"
                    ref="input_nodaftar"
                  />
                  <small
                    v-show="loadingCari"
                    style="position: absolute; top: 17px; right: 10px"
                    >Mencari data...</small
                  >
                </td>
              </tr>
              <tr>
                <td>Nama</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.nama_lengkap" />
                </td>
              </tr>
              <tr>
                <td>Angkatan</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.angkatan_2" />
                </td>
              </tr>
              <tr>
                <td>Kampus</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.kampus" />
                </td>
              </tr>
              <tr>
                <td>Jurusan</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.jurusan" />
                </td>
              </tr>
              <tr>
                <td>Marketing</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.marketing" />
                </td>
              </tr>
            </table>
          </form>
        </div>
        <div>
          <p>
            <b>History Pembayaran</b>
          </p>
          <div class="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th style="width: 40px">#</th>
                  <th style="width: 120px">Tgl Bayar</th>
                  <th style="width: 200px; text-align: right">Jumlah</th>
                  <th style="text-align: center">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in data.history_pembayaran"
                  :key="index"
                  :class="{ lunas: item.status == 'VALIDASI' }"
                >
                  <td style="width: 40px">{{ ++index }}</td>
                  <td>
                    {{ moment(item.tgl_bayar).format("DD MMM YYYY") }}
                  </td>
                  <td align="right">{{ item.jumlah_fix | number }}</td>
                  <td align="center">{{ item.status || "-" }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr style="border-top: 2px solid #ccc">
                  <td colspan="2">
                    <b>TOTAL PEMBAYARAN</b>
                  </td>
                  <td align="right" style="padding-right: 10px">
                    <b>{{ totalBayar | number }}</b>
                  </td>
                  <td></td>
                </tr>
                <tr
                  :class="data.kurangBayar < 0 ? 'kurang' : ''"
                  v-show="data.kurangBayar < 0"
                >
                  <td colspan="2">
                    <b>KURANG BAYAR</b>
                  </td>
                  <td align="right" style="padding-right: 10px">
                    <b>{{
                      (data.kurangBayar > 0 ? 0 : data.kurangBayar) | number
                    }}</b>
                  </td>
                  <td></td>
                </tr>
                <tr v-show="data.kurangBayar > 0">
                  <td colspan="2">
                    <b>LEBIH BAYAR</b>
                  </td>
                  <td align="right" style="padding-right: 10px">
                    <b>{{ data.kurangBayar | number }}</b>
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost } from "../api";
import moment from "moment";

export default {
  name: "Home",
  data() {
    return {
      loadingSave: false,

      dataPerusahaan: [],
      data: {
        id: this.$route.params.id,
        id_perusahaan: [],
      },
    };
  },
  methods: {
    moment,
    async save() {
      if (!this.data.nama) {
        this.$toast("Nama harus diisi");
        return;
      }

      this.loadingSave = true;
      const res = await apiPost(`/v1/pos`, this.data);
      this.loadingSave = false;

      if (res.valid) {
        this.$toast("Berhasil disimpan");
        this.$emit("reload");

        if (this.data.id < 0) {
          this.data.id = -1;
          this.data.nama = "";

          this.$refs["input_nama"].focus();
        } else {
          this.$router.back();
        }
      }
    },
    async load() {
      const res = await apiGet(`/v1/pos?id=${this.data.id}`);
      this.data = res.data;
      this.data.id_perusahaan = [];

      this.data.detail.forEach((item) => {
        this.data.id_perusahaan.push(item.id_perusahaan);
      });

      console.log(this.data);
    },
    async loadPerusahaan() {
      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;
    },
  },
  mounted() {
    if (this.data.id > 0) {
      this.load();
    }
    this.loadPerusahaan();

    this.$refs["input_nama"].focus();
  },
};
</script>

<style lang="scss" scoped>
h1 {
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    cursor: pointer;
  }
}

.content {
  width: 600px;
  .table-inline {
    select {
      width: 100%;
    }
    input {
      width: calc(100% - 22px);
    }
    table {
      width: 100%;
      tr {
        td {
          &:nth-child(1) {
            width: 100px;
          }
          &:nth-child(2) {
            width: 10px;
          }
          &.btn-group {
            display: flex;
            gap: 10px;
          }
        }
      }
    }
    button {
      width: 150px;
    }
  }
  select[multiple] {
    option {
      padding: 6px 0;
      &:checked,
      &:focus {
        background-color: var(--blue);
        color: #fff;
      }
    }
  }

  .table-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
  }
}

form.saving {
  opacity: 0.5;
}
</style>