<template>
  <div class="home">
    <h1>{{ data.id > 0 ? "Ubah" : "Tambah" }} User</h1>

    <div class="content">
      <form class="table-inline" @submit.prevent="save()">

        <div class="content-group">
          <div>
            <table>
              <tr>
                <td>Nama</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.nama" />
                </td>
              </tr>
              <tr>
                <td>Akses</td>
                <td>:</td>
                <td>
                  <select v-model="data.akses">
                    <option value="HEAD OFFICE">HEAD OFFICE</option>
                    <option value="CABANG">CABANG</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Perusahaan</td>
                <td>:</td>
                <td>
                  <select v-model="data.id_perusahaan">
                    <option :value="item.id" v-for="(item, index) in dataPerusahaan" :key="index">
                      {{ item.nama }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Pemilik Rekening</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.pemilik_rekening">
                </td>
              </tr>
              <tr>
                <td>Bank</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.bank">
                </td>
              </tr>
              <tr>
                <td>No Rekening</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.no_rekening">
                </td>
              </tr>
              <tr>
                <td>Password</td>
                <td>:</td>
                <td>
                  <input type="password" v-model="data.password" v-if="data.id < 0 || showPassword" />
                  <span class="password" @click="lihatPassword()" v-if="data.id > 0 && !showPassword">
                    Ubah password
                  </span>
                </td>
              </tr>
            </table>
          </div>

          <div>
            <p style="margin-top: 05px;">Akses ke Perusahaan Lain</p>
            <select v-model="userPerusahaan" multiple style="height: 260px;">
              <option :value="item.id" v-for="(item, index) in dataPerusahaan" :key="index">
                {{ item.nama }}
              </option>
            </select>
          </div>
        </div>


        <div class="btn-group">
          <button type="submit" class="btn">SIMPAN</button>
          <button type="button" class="btn btn-outline" @click="$router.go(-1)">
            KEMBALI
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost } from "../api";

export default {
  name: "UserForm",
  data() {
    return {
      loading: false,
      dataPerusahaan: [],
      userPerusahaan: [],
      data: {
        id: this.$route.params.id,
      },

      showPassword: false,
    };
  },
  methods: {
    async loadPerusahaan() {
      this.$loading(true);

      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;

      if (this.data.id < 0) {
        setTimeout(() => {
          this.$loading(false);
        }, 150);
      }
    },
    async load() {
      this.$loading(true);

      const res = await apiGet(`/v1/user?id=${this.data.id}`);

      setTimeout(() => {
        this.data = res.data;

        if (res.data.user_perusahaan) {
          let user_perusahaan = [];
          res.data.user_perusahaan.forEach((item) => {
            user_perusahaan.push(item.id_perusahaan);
          })

          this.userPerusahaan = user_perusahaan;
        }

        this.$loading(false);
      }, 150);
    },
    async save() {
      if (!this.data.nama || !this.data.id_perusahaan || !this.data.akses) {
        this.$toast("Lengkapi form terlebihi dahulu");
        return;
      }

      this.$loading(true);

      this.data.akses_id_perusahaan = this.userPerusahaan;
      await apiPost(`/v1/user`, this.data);
      
      this.$loading(false);
      this.$router.back();
    },
    async lihatPassword() {
      const password = prompt("Masukkan password Anda");
      if (password) {
        const param = {
          id: this.$store.state.setting.login.id,
          password,
        };

        const res = await apiPost(`/v1/user/challenge`, param);
        if (res.valid) {
          this.showPassword = true;
        } else {
          alert("Password yang Anda masukkan salah");
        }
      }
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "user");

    this.loadPerusahaan();
    if (this.data.id > 0) {
      this.load();
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 0;
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
    cursor: pointer;
  }
}

.content {
  width: 600px;

  .table-inline {

    .content-group {
      display: flex;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

    }

    .btn-group {
      display: flex;
      gap: 10px;
      margin-top: 20px;
      padding-left: 170px;
    }

    input {
      width: calc(100% - 22px);
    }

    select {
      width: 100%;
    }

    table {
      tr {
        td {
          &:nth-child(1) {
            width: 130px;
          }

          &:nth-child(2) {
            width: 5px;
          }

          span.password {
            display: flex;
            padding: 10px 0;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

        }
      }
    }

    button {
      width: 150px;
    }
  }
}
</style>