import { render, staticRenderFns } from "./Realisasi.vue?vue&type=template&id=0e7faca8&scoped=true"
import script from "./Realisasi.vue?vue&type=script&lang=js"
export * from "./Realisasi.vue?vue&type=script&lang=js"
import style0 from "./Realisasi.vue?vue&type=style&index=0&id=0e7faca8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.19_css-loader@6.10.0_webpack@5.90.3__lodash@4.17.21__kpo7soznxrpys4cf4nmv6uorri/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e7faca8",
  null
  
)

export default component.exports