<template>
  <div class="home">
    <div style="display: flex; justify-content: space-between; align-items: center">
      <h1>Input Transaksi Baru</h1>
      <h3 :class="{ 'red': saldo < 0 }">
        Saldo Terakhir: <b>{{ saldo | number }}</b>
      </h3>
    </div>

    <div class="content">
      <form class="table-inline" @submit.prevent="save">
        <table>
          <tr>
            <td>Tanggal</td>
            <td>:</td>
            <td>
              <date-range-picker v-model="dateRange" :single-date-picker="true" :auto-apply="true" :ranges="false">
                <template v-slot:input="picker">
                  {{ moment(picker.startDate).format("DD-MM-YYYY") }}
                </template>
              </date-range-picker>
            </td>
          </tr>
          <tr>
            <td>Keterangan</td>
            <td>:</td>
            <td>
              <select v-model="data.id_pengajuan" @change="fillForm()" ref="input_keterangan"
                :class="{ invalid: data.id_pengajuan == -1 && submitted }">
                <option value="-1">-- Pilih Pengajuan --</option>
                <option v-for="(item, index) in dataPengajuan" :key="index" :value="item.id">
                  {{ item.kebutuhan }}
                </option>
                <option value="LAIN">Lainnya</option>
              </select>
            </td>
          </tr>
          <tr v-show="data.id_pengajuan == 'LAIN'">
            <td>Kebutuhan</td>
            <td>:</td>
            <td>
              <input type="text" v-model="data.keterangan" ref="input_keterangan_text" />
            </td>
          </tr>
          <tr>
            <td>Jumlah</td>
            <td>:</td>
            <td>
              <input type="text" v-model="data.jumlah" v-thousand-separator
                :class="{ invalid: !data.jumlah && submitted }" />
            </td>
          </tr>
          <tr>
            <td>Tipe</td>
            <td>:</td>
            <td>
              <select v-model="data.tipe">
                <option value="UANG MASUK">UANG MASUK</option>
                <option value="UANG KELUAR">UANG KELUAR</option>
              </select>
            </td>
          </tr>
          <tr v-show="data.tipe == 'UANG KELUAR'">
            <td>Pos</td>
            <td>:</td>
            <td>
              <select v-model="data.id_pos" :class="{ invalid: !data.id_pos && submitted }">
                <option v-for="(item, index) in dataPos" :key="index" :value="item.id">
                  {{ item.nama }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Bukti Nota</td>
            <td>:</td>
            <td>
              <input type="file" @change="onFileChange" ref="input_file" accept="image/*"
                :class="{ invalid: !selectedFile && submitted }" />
              <div v-show="data.bukti" style="margin: 10px 0">
                <a :href="data.bukti" target="_blank">
                  <img :src="data.bukti" width="100%" />
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="btn-group">
              <button type="submit" class="btn" ref="btn_simpan">SIMPAN</button>
              <button type="button" class="btn btn-outline" @click="$router.go(-1)">
                TUTUP
              </button>
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>

<script>
import { apiGet, apiUpload } from "../api";

import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Home",
  data() {
    return {
      data: {
        id: this.$route.params.id,
        id_pengajuan: -1,
        tipe: "UANG KELUAR",
      },
      dataPos: [],
      dataPengajuan: [],

      selectedFile: null,
      submitted: false,

      dateRange: {
        startDate: moment().format("YYYY-MM-DD"),
      },
      saldo: 0,
    };
  },
  components: {
    DateRangePicker,
  },
  methods: {
    moment,
    onFileChange(event) {
      this.selectedFile = event.target.files[0];

      const maxFileSizeMB = 2;
      const fileSizeMB = this.selectedFile.size / (1024 * 1024);

      if (fileSizeMB > maxFileSizeMB) {
        alert(
          `Ukuran file terlalu besar. Ukuran maksimum adalah ${maxFileSizeMB} MB.`
        );
      }
    },
    objectToFormData(obj) {
      const formData = new FormData();

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          formData.append(key, obj[key]);
        }
      }
      return formData;
    },
    fillForm() {
      if (this.data.id_pengajuan) {
        if (this.data.id_pengajuan == "LAIN") {
          setTimeout(() => {
            this.$refs.input_keterangan_text.focus();
          }, 150);
        } else {
          const selected = this.dataPengajuan.find(
            (k) => k.id == this.data.id_pengajuan
          );

          this.data.jumlah = selected.realisasi;
          this.data.keterangan = selected.kebutuhan;
        }
      }
    },
    async getSaldo() {
      const res = await apiGet(
        `/v1/saldo?id_perusahaan=${this.$store.state.setting.login.id_perusahaan}`
      );

      this.saldo = res.data.saldo;
    },
    async loadPengajuan() {
      const res = await apiGet(
        `/v1/pengajuan?id_perusahaan=${this.$store.state.setting.login.id_perusahaan}&id_transaksi=null&is_realisasi=1`
      );
      this.dataPengajuan = res.data;
    },
    async loadPos() {
      const res = await apiGet(`/v1/pos`);
      this.dataPos = res.data;
    },
    async save() {
      this.submitted = true;

      if (
        !this.data.keterangan ||
        !this.data.jumlah ||
        !this.selectedFile
      ) {
        return;
      }

      if (this.data.tipe == 'UANG KELUAR' && !this.data.id_pos) {
        return;
      }

      this.data.tgl_transaksi = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.data.saldo = this.saldo;
      this.data.jumlah = parseInt(
        this.data.jumlah.toString().replace(/\./g, "")
      );

      let formData = this.objectToFormData(this.data);
      formData.append("file", this.selectedFile);

      this.$refs.btn_simpan.disabled = true;
      const res = await apiUpload(`/v1/transaksi`, formData);
      if (res.valid) {
        this.submitted = false;
        this.$toast("Berhasil disimpan");

        this.data = {
          id: -1,
          keterangan: null,
          jumlah: null,
          tipe: "UANG KELUAR",
          pos: null,
        };

        this.$refs.input_file.value = null;
        this.$refs.input_keterangan.focus();

        this.getSaldo();
        this.loadPengajuan();

        this.$refs.btn_simpan.disabled = false;

        this.$emit("reload");
      }
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "transaksi");
    this.getSaldo();
    this.loadPos();
    this.loadPengajuan();

    this.$refs["input_keterangan"].focus();
  },
};
</script>

<style lang="scss" scoped>
h1,
h3 {
  margin-top: 0;
}

.content {
  width: 600px;

  .table-inline {
    select {
      width: 100%;
    }

    input {
      width: calc(100% - 22px);
    }

    table {
      width: 100%;

      tr {
        td {
          &:nth-child(1) {
            width: 100px;
          }

          &:nth-child(2) {
            width: 10px;
          }

          &.btn-group {
            display: flex;
            gap: 10px;
          }
        }
      }
    }

    button {
      width: 150px;
    }
  }
}

.red {
  color: #f00;
}

.saldo {
  background-color: var(--green);
  color: #fff;
  border-radius: 6px;
  padding: 6px 12px;
}
</style>