<template>
  <div class="body">
    <div class="login">
      <div class="bg">
        <div class="card">
          <p>
            <img
              src="logo.png"
              alt="Logo PSPP"
            />
          </p>
        </div>
      </div>
      <div class="cards">
        <form @submit.prevent="login()">
          <h1>Login Sistem Keuangan</h1>

          <div class="form-group">
            <label>User</label>
            <select v-model="data.id">
              <option value=""></option>
              <option
                :value="item.id"
                v-for="(item, index) in users"
                :key="index"
              >
                {{ item.nama }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Password</label>
            <input type="password" v-model="data.password" required />
          </div>
          <button type="submit" class="btn" :disabled="loading">LOGIN</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetNoMiddleware, login } from "../api";

export default {
  name: "Login",
  data() {
    return {
      users: [],
      data: {},

      loading: false,
    };
  },
  methods: {
    async userList() {
      try {
        const res = await apiGetNoMiddleware("/v1/user/list");
        if (res.valid) {
          this.users = res.data;
        }
      } catch (e) {
        alert(e.response.data.message);
      }
    },
    async login() {
      try {
        this.loading = true;

        if (this.data.id && this.data.password) {
          const res = await login("/v1/login", this.data);
          if (res.valid) {
            this.$store.commit("setting/setLogin", res.data);
            location.href = "/";
          }
        }
      } catch (e) {
        this.loading = false;
        
        alert(e.response.data.message);
      }
    },
  },
  mounted() {
    this.userList();
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100vh;
  background-color: #f4f7fc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #f7f7f7;
  width: 80%;
  height: 70%;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 2px 3px #ccc;
  .bg {
    background-color: #8ec5fc;
    background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    .card {
      background: rgba(255, 255, 255, 0.3);
      padding: 60px;
      h1 {
        color: #fff;
      }
    }
  }
  .cards {
    background-color: #fff;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      margin: 0 0 40px;
      font-weight: 600;
      font-size: 22px;
    }
  }

  form {
    width: 350px;
    margin: 0 auto;
  }

  input {
    width: calc(100% - 22px);
  }
  select {
    width: 100%;
    text-transform: uppercase;
  }

  button {
    width: 100%;
  }
}
</style>