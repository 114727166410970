<template>
  <div>
    <div id="login" v-if="$route.path == '/login'">
      <router-view />
    </div>
    <div id="app" v-else>
      <nav>
        <header>
          <h2>
            Sistem Keuangan
            <span>PSPP Penerbangan</span>
          </h2>
        </header>
        <ul>
          <li>
            <router-link to="/" :class="{ active: $store.state.setting.activePage == 'home' }">
              <img src="https://img.icons8.com/?size=32&id=yT1JsTcBt1WV&format=png">
              Home</router-link>
          </li>

          <li class="separator">
            <span></span>
          </li>

          <li>
            <router-link to="/pengajuan" :class="{
              active: $store.state.setting.activePage == 'pengajuan',
            }">
              <img src="https://img.icons8.com/?size=32&id=8idZc6CTLMhJ&format=png">
              Pengajuan
            </router-link>
          </li>
          <li>
            <router-link to="/realisasi" :class="{
              active: $store.state.setting.activePage == 'realisasi',
            }">
              <img src="https://img.icons8.com/?size=32&id=T0z4IPrxHIgk&format=png">
              Realisasi

              <span v-show="notif.realisasi > 0" class="notif">{{
                notif.realisasi
              }}</span>
            </router-link>
          </li>
          <li>
            <router-link to="/transaksi" :class="{
                  active: $store.state.setting.activePage == 'transaksi',
                }">
              <img src="https://img.icons8.com/?size=32&id=jrsRiLx2c8YH&format=png">
              Transaksi
            </router-link>
          </li>
          <li>
            <router-link to="/laporan" :class="{
              active: $store.state.setting.activePage == 'laporan',
            }">
              <img src="https://img.icons8.com/?size=32&id=x7VDN5jKIlNy&format=png">
              Laporan
            </router-link>
          </li>

          <li class="separator" v-if="user.akses == 'HEAD OFFICE'">
            <span></span>
          </li>

          <li v-if="user.akses == 'HEAD OFFICE'">
            <router-link to="/resume/realisasi" :class="{
              active: $store.state.setting.activePage == 'realisasi/resume',
            }">
              <img src="https://img.icons8.com/?size=32&id=zJgHSl1c947r&format=png">
              Resume Realisasi
            </router-link>
          </li>
          <!-- <li v-if="user.akses == 'HEAD OFFICE'">
            <router-link to="/resume/transaksi" :class="{
              active: $store.state.setting.activePage == 'resume/transaksi',
            }">
              <img src="https://img.icons8.com/?size=32&id=zJgHSl1c947r&format=png">
              Resume Transaksi
            </router-link>
          </li> -->

          <li class="separator">
            <span></span>
          </li>

          <li>
            <router-link to="/pembayaran-siswa" :class="{
              active: $store.state.setting.activePage == 'pembayaran-siswa',
            }">
              <img src="https://img.icons8.com/?size=32&id=MZAxWHbLSIH0&format=png">
              Pembayaran Siswa
            </router-link>
          </li>
          <li>
            <router-link to="/fmi" :class="{
              active: $store.state.setting.activePage == 'fmi',
            }">
              <img src="https://img.icons8.com/?size=32&id=DEjWOFVrVshS&format=png">
              FMI
            </router-link>
          </li>
          <li>
            <router-link to="/laporan-pembayaran" :class="{
              active: $store.state.setting.activePage == 'laporan-pembayaran',
            }">
              <img src="https://img.icons8.com/?size=32&id=x7VDN5jKIlNy&format=png">
              Laporan
            </router-link>
          </li>

          <li class="separator" v-if="user.akses == 'HEAD OFFICE'">
            <span></span>
          </li>

          <li v-if="user.akses == 'HEAD OFFICE'">
            <router-link to="/pos" :class="{ active: $store.state.setting.activePage == 'pos' }">
              <img src="https://img.icons8.com/?size=32&id=D1MVKHeBpjQx&format=png">
              Master POS
            </router-link>
          </li>
          <li v-if="user.akses == 'HEAD OFFICE'">
            <router-link to="/perusahaan" :class="{
              active: $store.state.setting.activePage == 'perusahaan',
            }">
              <img src="https://img.icons8.com/?size=32&id=JhsIcmZ1Vss0&format=png">
              Perusahaan
            </router-link>
          </li>
          <li v-if="user.akses == 'HEAD OFFICE'">
            <router-link to="/user" :class="{ active: $store.state.setting.activePage == 'user' }">
              <img src="https://img.icons8.com/?size=32&id=uOoIUTYvxnso&format=png">
              User
            </router-link>
          </li>

          <li class="separator">
            <span></span>
          </li>

          <li>
            <router-link to="/rekening" :class="{ active: $store.state.setting.activePage == 'rekening' }">
              <img src="https://img.icons8.com/?size=32&id=mN6rnipbRP5D&format=png">
              Rekening Bank
            </router-link>
          </li>
        </ul>
      </nav>
      <article>
        <div class="logout" title="Logout">
          <p>{{ user.nama }}</p>
          <span @click="openNotifikasi()">
            <img src="https://img.icons8.com/?size=32&id=H1UeFcv5ogFU&format=png" height="24">
            <small v-show="newNotif" class="badge"></small>

            <div class="notifications-list" v-show="showNotif">
              <ul>
                <li v-for="(item, index) in dataNotifikasi" :key="index" :class="item.dibuka ? 'dibaca' : ''">
                  <p v-html="item.judul"></p>
                  <small>{{
                    moment(item.created_at).format("DD/MM/YY HH:mm")
                  }}</small>
                </li>
              </ul>
            </div>
          </span>
          <span @click="logout()">
            <img src="https://img.icons8.com/?size=32&id=MSUWfu9MhE92&format=png" height="24">
          </span>
        </div>
        <div class="main-content">
          <router-view />
        </div>
        <div class="loading" v-show="$store.state.setting.loading == true">
          <div class="dot-loader"></div>
        </div>
      </article>
    </div>

    <div class="overlay" v-show="showNotif" @click="closeNotif"></div>
  </div>
</template>

<script>
import { apiGet, apiPost } from "./api";
import moment from "moment";

export default {
  data() {
    return {
      user: {},
      notif: {
        realisasi: 0
      },

      showNotif: false,
      newNotif: false,

      dataNotifikasi: [],
    };
  },
  methods: {
    moment,
    closeNotif(e) {
      if (e.target.classList.contains("overlay")) {
        this.showNotif = false;
      }
    },
    openNotifikasi() {
      this.showNotif = true;

      setTimeout(() => {
        this.newNotif = false;
        apiPost(`/v1/notifikasi/open`);
        this.loadNotif();
      }, 1500);
    },
    async loadNotif() {
      const res = await apiGet("/v1/notifikasi");
      const data = res.data;

      data.forEach((item) => {
        if (!item.dibuka) {
          this.newNotif = true;
        }
      });

      this.dataNotifikasi = data;
    },
    async countNotif() {
      const res = await apiGet("/v1/pengajuan/count");
      const data = res.data;

      this.notif.realisasi = data;
    },
    logout() {
      localStorage.clear();
      location.href = "/";
    },
  },
  mounted() {
    if (this.$route.path != "/login") {
      if (!this.$store.state.setting.isLogin) {
        this.$router.push("/login");
      } else {
        this.user = this.$store.state.setting.login;
        this.loadNotif();

        if (this.user.akses == "HEAD OFFICE") {
          this.countNotif();
        }
      }
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600&display=swap");

:root {
  --primary-disabled: #727577;
  --primary: #3b4852;
  --primary-darker: #2b3846;
  --green-disabled: #52c08c;
  --green: #089D36;
  --green-darker: #05884b;
  --red: #f00;
  --orange: rgb(238, 135, 0);

  --light-blue: #e6f4ff;
  --blue-disabled: #95c1ff;
  --blue: #377CF6;
  --blue-hover: #6aa1ff;
  --blue-active: #3273e4;

}

* {
  font-family: "Maven Pro", sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  color: var(--primary);
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;

  &.bold {
    font-variation-settings: "FILL" 0, "wght" 900, "GRAD" 0, "opsz" 48;
  }
}

#app {
  height: 100vh;
  overflow: hidden;

  display: grid;
  grid-template-columns: 250px 1fr;

  header {
    h2 {
      margin: 10px 0 20px;

      span {
        font-size: 17px;
        font-weight: 400;
      }
    }
  }

  article {
    padding: 10px 30px 20px;
    position: relative;
    background: #eaeef9;
  }

  nav {
    padding: 20px 10px 20px 20px;

    ul {
      margin: 50px 0 0 0;
      padding: 0 10px 0 0;
      list-style-type: none;
      height: calc(100vh - 160px);
      overflow: auto;

      li {
        a {
          display: flex;
          gap: 5px;
          align-items: center;
          padding: 15px;
          color: var(--primary);
          text-decoration: none;
          border-radius: 10px;
          margin: 5px 0;
          transition: border-color 0.3s, background 0.3s,
            padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

          img {
            height: 24px;
          }

          span {
            margin-right: 5px;
          }

          &:hover {
            background-color: var(--light-blue);
          }

          &.active {
            background-color: var(--light-blue);
            color: var(--blue);
            font-weight: 500;

            span {
              color: var(--blue);

              &.notif {
                color: #fff;
              }
            }
          }
        }

        &.separator {
          span {
            display: block;
            height: 20px;
          }
        }

        span.notif {
          background-color: #f00;
          color: #fff;
          width: 24px;
          height: 24px;
          border-radius: 30px;
          font-size: 13px;
          font-weight: bold;
          text-align: center;
          line-height: 24px;
          margin-left: 10px;
        }
      }
    }
  }
}

.main-content {
  width: calc(100vw - 310px);
  overflow-x: auto;
}

.card {
  border-radius: 15px;
  padding: 20px;
  background: #fff;
}

.form-group {
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;
  }
}

input,
select,
textarea {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  outline: none;
  font-size: 15px;

  &:focus {
    border: 1px solid var(--blue);
  }

  &.invalid {
    border: 1px solid var(--red);
  }
}

.btn {
  display: block;
  background-color: var(--blue);
  color: #fff;
  border: 0; //1px solid var(--blue);
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  padding: 12px;
  text-decoration: none;
  text-align: center;
  font-size: 14px;

  &:disabled {
    background-color: var(--blue-disabled);

    &:hover {
      background-color: var(--blue-disabled);
    }
  }

  &.btn-outline {
    background-color: #fff;
    border: 1px solid var(--blue);
    color: var(--blue);

    &:active {
      background-color: #eee;
    }
  }

  &.btn-green {
    background-color: var(--green);
    border: 1px solid var(--green);

    &:active {
      background-color: var(--green-darker);
      border: 1px solid var(--green-darker);
    }

    &:disabled {
      background-color: var(--green-disabled);
    }
  }

  &:active {
    background-color: var(--blue-active);
  }
}

.logout {
  position: absolute;
  right: 30px;
  top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
  }

  span {
    cursor: pointer;
    background: #fff;
    width: 44px;
    height: 44px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 20px;
    }

    &.notifications {
      position: relative;

      small.badge {
        position: absolute;
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 20px;
        background: #f00;
        top: -5px;
        right: -5px;
      }

      .notifications-list {
        position: absolute;
        background: #fff;
        right: 0;
        border: 1px solid #ccc;
        border-radius: 10px;
        z-index: 9;
        margin-top: 15px;

        ul {
          list-style-type: none;
          margin: 0;
          padding: 20px;

          li {
            font-size: 16px;
            padding: 15px 0;
            border-bottom: 1px solid #ccc;

            &.dibaca {
              font-variation-settings: normal;
            }

            p {
              margin: 0;
              padding: 6px 0;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.table-inline {
  table {
    tr {
      td {
        padding: 5px 0 2px;

        &:first-child {
          padding-right: 10px;
        }

        &:nth-child(2) {
          padding-right: 20px;
        }
      }
    }
  }
}

.table-wrapper {
  overflow: auto;
  border-radius: 10px;
  background: #fff;

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 15px;

    thead {
      tr {
        th {
          position: sticky;
          top: 0;
          background: #fff;
          border-bottom: 2px solid #999;
          padding: 14px 10px;
          text-align: left;
        }
      }
    }

    tbody,
    tfoot {
      tr {
        cursor: pointer;

        &:hover {
          td {
            background-color: #f7f7f7;
          }

          &.no-hover {
            td {
              background-color: #fff;
              cursor: default;
            }
          }
        }

        &:active {
          td {
            background-color: #eee;
          }
        }

        td {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          font-size: 14px;
        }

        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      .btn-hapus {
        color: var(--red);
        padding: 4px 8px;
        border-radius: 6px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          background: var(--red);
          color: #fff;
        }
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 9;

  .modal-body {
    padding: 20px;
    margin-top: 50px;
    background-color: #fff;
    border-radius: 10px;
    height: max-content;
    min-width: 400px;
    max-height: 70vh;
    overflow: auto;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: rgba(255, 255, 255, 0.4);
  transition: all 0.5s ease;

  &.fade-out {
    opacity: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.filter-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 8;
}

.filter-content {
  background: #fff;
  position: absolute;
  top: 0;
  right: -500px;
  padding: 30px;
  z-index: 9;
  height: calc(100vh - 60px);
  overflow-y: auto;
  transition: all 0.4s ease;

  &.active {
    right: 0;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-content: center;

    img {
      cursor: pointer;
    }
  }

  form {
    width: 350px;

    input {
      width: calc(100% - 22px);
    }

    select {
      width: 100%;
    }

    .btn-group {
      display: flex;
      gap: 15px;

      button {
        width: 120px;
      }
    }
  }
}

.filter-status {
  padding: 0px 0px 15px;
  display: flex;
  gap: 10px;

  span {
    background: #fff;
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 20px;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.75);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.vue-daterange-picker {
  display: block !important;
}

.daterangepicker:before,
.daterangepicker:after {
  border: 0;
}

.reportrange-text {
  padding: 10px !important;
  border-radius: 8px !important;
  font-size: 15px;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

/* HTML: <div class="loader"></div> */
.dot-loader {
  width: 13px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 var(--blue), -20px 0 var(--blue-disabled);
    background: var(--blue);
  }

  33% {
    box-shadow: 20px 0 var(--blue), -20px 0 var(--blue-disabled);
    background: var(--blue-disabled);
  }

  66% {
    box-shadow: 20px 0 var(--blue-disabled), -20px 0 var(--blue);
    background: var(--blue-disabled);
  }

  100% {
    box-shadow: 20px 0 var(--blue-disabled), -20px 0 var(--blue);
    background: var(--blue);
  }
}
</style>
