<template>
  <div class="no-rekening">
    <h1>Rekening Bank</h1>

    <div class="content">
      <form class="table-inline" @submit.prevent="save()">
        <table>
          <tr>
            <td>Bank</td>
            <td>:</td>
            <td>
              <input type="text" v-model="data.bank" />
            </td>
          </tr>
          <tr>
            <td>No Rekening</td>
            <td>:</td>
            <td>
              <input type="text" v-model="data.no_rekening" />
            </td>
          </tr>
          <tr>
            <td>Nama Pemilik Rekening</td>
            <td>:</td>
            <td>
              <input type="text" v-model="data.pemilik_rekening" />
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="btn-group">
              <button type="submit" class="btn" :disabled="loading">SIMPAN</button>
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>    

<script>
import { apiGet, apiPost } from '@/api';

export default {
  data() {
    return {
      loading: false,
      data: {}
    }
  },
  methods: {
    async load() {
      const res = await apiGet('/v1/user/rekening')
      this.data = res.data;

      console.log(this.data)
    },
    async save() {
      this.loading = true;
      await apiPost('/v1/user/rekening', this.data);
      this.loading = false;

      this.$toast('Rekening berhasil diupdate');
    }
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "rekening");

    this.load();
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin-top: 30px;
  width: calc(100% - 40px);
  height: calc(100vh - 160px);
  background: #fff;
  padding: 20px;
  border-radius: 10px;

  .table-inline {
    width: 600px;

    table {
      width: 100%;

      tr {
        td {
          &:nth-child(1) {
            width: 180px;
          }

          &:nth-child(2) {
            width: 6px;
          }
        }
      }
    }

    input {
      width: calc(100% - 22px);
    }

    button {
      width: 120px;
    }
  }
}
</style>