<template>
  <div class="pengajuan-form">
    <div>
      <h1>Input Pembayaran</h1>
    </div>

    <div class="content" ref="content">
      <form class="table-inline" @submit.prevent="save">
        <div class="table-group">
          <div>
            <p>
              <b>Cari Siswa</b>
            </p>
            <table>
              <tr>
                <td>No Daftar</td>
                <td>:</td>
                <td style="position: relative">
                  <input
                    type="text"
                    v-model="noDaftar"
                    @keydown.enter.prevent="handleKeyUp"
                    placeholder="Cari siswa berdasarkan no daftar"
                    ref="input_nodaftar"
                  />
                  <small
                    v-show="loadingCari"
                    style="position: absolute; top: 17px; right: 10px"
                    >Mencari data...</small
                  >
                </td>
              </tr>
              <tr>
                <td>Nama</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.nama_lengkap" />
                </td>
              </tr>
              <tr>
                <td>Angkatan</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.angkatan_2" />
                </td>
              </tr>
              <tr>
                <td>Kampus</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.kampus" />
                </td>
              </tr>
              <tr>
                <td>Jurusan</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.jurusan" />
                </td>
              </tr>
              <tr>
                <td>Marketing</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.marketing" />
                </td>
              </tr>
              <tr>
                <td>No Induk</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.no_induk" readonly/>
                </td>
              </tr>
              <tr>
                <td>No Lunas</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.no_lunas" readonly/>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <div style="display: flex;justify-content: space-between;align-items: center">
              <p style="display: flex; justify-content: space-between">
                <b>{{
                    $store.state.setting.login.akses == "CABANG"
                        ? "Input Pembayaran"
                        : "Validasi Pembayaran"
                  }}</b>
              </p>

              <span class="total-bayar" v-show="data.id > 0">
                {{ totalBayar | number }}
              </span>
            </div>

            <table>
              <tr>
                <td>Tgl Bayar</td>
                <td>:</td>
                <td>
                  <date-range-picker
                    v-model="dateRange"
                    :single-date-picker="true"
                    :auto-apply="true"
                    :ranges="false"
                  >
                    <template v-slot:input="picker">
                      {{ moment(picker.startDate).format("DD-MM-YYYY") }}
                    </template>
                  </date-range-picker>
                </td>
              </tr>

              <tr>
                <td>Jumlah</td>
                <td>:</td>
                <td>
                  <input
                    type="text"
                    v-model="data.jumlah"
                    v-thousand-separator
                    placeholder="Sampai 4 digit terakhir"
                    @input="updateJumlahFix"
                  />
                </td>
              </tr>
              <tr v-if="$store.state.setting.login.akses == 'HEAD OFFICE'">
                <td>Potongan Wisuda</td>
                <td>:</td>
                <td>
                  <input
                    type="text"
                    v-model="data.potongan_wisuda"
                    v-thousand-separator
                    :class="data.potongan_wisuda > 0 ? 'blinking-element' : ''"
                    @input="updateJumlahFix"
                  />
                </td>
              </tr>
              <tr>
                <td>Jumlah Fix</td>
                <td>:</td>
                <td>
                  <input
                    type="text"
                    v-model="data.jumlah_fix"
                    v-thousand-separator
                  />
                </td>
              </tr>
              <tr>
                <td>Keterangan</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.keterangan" />
                </td>
              </tr>
              <tr v-if="$store.state.setting.login.akses == 'HEAD OFFICE'">
                <td>FMI 15%</td>
                <td>:</td>
                <td>
                  <input
                    type="text"
                    v-model="data.jumlah_fmi"
                    v-thousand-separator
                  />
                </td>
              </tr>

              <tr v-if="$store.state.setting.login.akses == 'HEAD OFFICE'">
                <td>Potongan</td>
                <td>:</td>
                <td>
                  <input
                    type="text"
                    v-model="data.potongan"
                    v-thousand-separator
                    @input="calculateFMI"
                  />
                </td>
              </tr>
              <tr v-if="$store.state.setting.login.akses == 'HEAD OFFICE'">
                <td>FMI Diterima</td>
                <td>:</td>
                <td>
                  <input
                    type="text"
                    v-model="data.jumlah_fmi_diterima"
                    readonly
                  />
                </td>
              </tr>
              <tr v-if="$store.state.setting.login.akses == 'CABANG'">
                <td valign="top" style="padding-top: 15px">Bukti</td>
                <td valign="top" style="padding-top: 15px">:</td>
                <td>
                  <input
                    type="file"
                    @change="onFileChange"
                    ref="input_file"
                    accept="image/*"
                  />
                  <span
                    v-show="data.bukti"
                    class="lihat-bukti"
                    @click="modalOpen = true"
                  >
                    Lihat Bukti
                  </span>
                  <div v-show="false" style="margin: 10px 0">
                    <a :href="data.bukti" target="_blank">
                      <img :src="data.bukti" width="100%" />
                    </a>
                  </div>
                </td>
              </tr>
              <tr v-show="data.status == 'DITOLAK'">
                <td>Alasan ditolak</td>
                <td>:</td>
                <td>{{ data.alasan_ditolak }}</td>
              </tr>
              <tr v-if="$store.state.setting.login.akses == 'CABANG'">
                <td></td>
                <td></td>
                <td style="display: flex; gap: 10px">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    ref="btn_simpan"
                  >
                    {{ data.status == "DITOLAK" ? "AJUKAN KEMBALI" : "SIMPAN" }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline"
                    @click="$router.back()"
                  >
                    KEMBALI
                  </button>
                </td>
              </tr>

              <tr v-if="$store.state.setting.login.akses == 'HEAD OFFICE'">
                <td></td>
                <td></td>
                <td style="display: flex; gap: 10px">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="modalOpen = true"
                  >
                    LIHAT BUKTI
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline"
                    @click="$router.back()"
                  >
                    KEMBALI
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="table-group">
          <div>
            <div v-show="false">
              <p>
                <b>Biaya Pendidikan</b>
              </p>
              <div class="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th style="width: 40px">#</th>
                      <th style="width: 200px">Keterangan</th>
                      <th style="width: 120px; text-align: right">Jumlah</th>
                      <th style="text-align: center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in data.biaya_pendidikan"
                      :key="index"
                      :class="{ lunas: item.status == 'LUNAS' }"
                    >
                      <td style="width: 40px">{{ ++index }}</td>
                      <td>{{ item.keterangan }}</td>
                      <td align="right">{{ item.total | number }}</td>
                      <td align="center">{{ item.status }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr style="border-top: 2px solid #ccc">
                      <td colspan="2">
                        <b>TOTAL BIAYA PENDIDIKAN</b>
                      </td>
                      <td align="right" style="padding-right: 10px">
                        <b>{{
                          data.biaya_pendidikan
                            ? data.biaya_pendidikan[3].sumBiaya
                            : "0" | number
                        }}</b>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div>
            <p>
              <b>History Pembayaran</b>
            </p>
            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th style="width: 40px">#</th>
                    <th style="width: 120px">Tgl Bayar</th>
                    <th style="width: 200px; text-align: right">Jumlah</th>
                    <th style="text-align: center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in data.history_pembayaran"
                    :key="index"
                    :class="{ lunas: item.status == 'VALIDASI' }"
                  >
                    <td style="width: 40px">{{ ++index }}</td>
                    <td>
                      {{ moment(item.tgl_bayar).format("DD MMM YYYY") }}
                    </td>
                    <td align="right">{{ item.jumlah_fix | number }}</td>
                    <td align="center">{{ item.status || "-" }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr style="border-top: 2px solid #ccc">
                    <td colspan="2">
                      <b>TOTAL PEMBAYARAN</b>
                    </td>
                    <td align="right" style="padding-right: 10px">
                      <b>{{ totalBayar | number }}</b>
                    </td>
                    <td></td>
                  </tr>
                  <tr
                    :class="data.kurangBayar < 0 ? 'kurang' : ''"
                    v-show="data.kurangBayar < 0"
                  >
                    <td colspan="2">
                      <b>KURANG BAYAR</b>
                    </td>
                    <td align="right" style="padding-right: 10px">
                      <b>{{
                        (data.kurangBayar > 0 ? 0 : data.kurangBayar) | number
                      }}</b>
                    </td>
                    <td></td>
                  </tr>
                  <tr v-show="data.kurangBayar > 0">
                    <td colspan="2">
                      <b>LEBIH BAYAR</b>
                    </td>
                    <td align="right" style="padding-right: 10px">
                      <b>{{ data.kurangBayar | number }}</b>
                    </td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="modal" v-show="modalOpen" @click="closeModal">
      <div class="modal-body">
        <div class="top">
          <h3 style="margin-top: 0">Bukti Pembayaran</h3>
          <p>
            <img :src="data.bukti" width="100%" />
          </p>
        </div>
        <div class="footer">
          <div>
            <button
              type="button"
              class="btn btn-primary"
              style="width: 120px"
              v-if="$store.state.setting.login.akses != 'CABANG'"
              @click="validasi()"
              :disabled="loadingValidasi"
            >
              {{ loadingValidasi ? "LOADING..." : "VALIDASI" }}
            </button>
            <button
              type="button"
              class="btn btn-outline"
              style="width: 120px; color: #f00; border-color: #f00"
              v-if="$store.state.setting.login.akses != 'CABANG'"
              @click="inputAlasan()"
            >
              TOLAK
            </button>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-outline"
              style="width: 120px"
              @click="modalOpen = false"
            >
              TUTUP
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="modalSiswaOpen" @click="closeModal">
      <div class="modal-body modal-siswa">
        <h2>{{ noDaftar }}</h2>
        <p>Pilih salah satu siswa di bawah ini</p>

        <ul>
          <li
            v-for="(item, index) in listSiswa"
            :key="index"
            @click="loadDataDetail(item)"
          >
            <p>
              <b>{{ item.nama_lengkap }}</b>
            </p>
            <span style="display: block; margin-bottom: 6px"
              >{{ item.no_daftar }} {{ item.prefix_daftar }}</span
            >
            <small>
              {{ item.kampus }} - {{ item.jurusan }} -
              {{ item.angkatan_2 }}
            </small>
          </li>
        </ul>
      </div>
    </div>

    <div class="loading" v-show="loadingCari">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p>
        {{ loadingText }}
      </p>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost, apiUpload, rawGet } from "../api";
import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "PengajuanForm",
  data() {
    return {
      dataPerusahaan: [],
      noDaftar: "",
      data: {
        id: this.$route.params.id,
        potongan_wisuda: 0,
        potongan: 0,
      },
      dateRange: {
        startDate: moment().format("YYYY-MM-DD"),
      },
      selectedFile: null,

      loadingCari: false,
      loadingValidasi: false,
      loadingText: "Mencari data siswa...",

      totalBayar: 0,
      modalOpen: false,

      modalSiswaOpen: false,
      listSiswa: [],

      nominalLunas: 0,
    };
  },
  components: {
    DateRangePicker,
  },
  computed: {},
  methods: {
    moment,
    onFileChange(event) {
      this.selectedFile = event.target.files[0];

      const maxFileSizeMB = 2;
      const fileSizeMB = this.selectedFile.size / (1024 * 1024);

      if (fileSizeMB > maxFileSizeMB) {
        alert(
          `Ukuran file terlalu besar. Ukuran maksimum adalah ${maxFileSizeMB} MB.`
        );
      }
    },
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.modalOpen = false;
        this.modalSiswaOpen = false;
      }
      // this.$emit("closeModal");
    },
    async handleKeyUp() {
      try {
        this.loadingCari = true;
        // this.$loading(true);

        // data siswa
        const res = await apiGet(
          "/v1/siswa?no_daftar=" + this.noDaftar
        );

        if (res.data.length <= 0) {
          alert("Data siswa tidak ditemukan");
          this.noDaftar = "";
          this.loadingCari = false;

          this.data = {
            id: this.$route.params.id,
          };
          return;
        }

        if (res.data.length > 1) {
          this.modalSiswaOpen = true;
          this.listSiswa = res.data;

          this.loadingCari = false;
          // this.$loading(false);
          return;
        }

        this.loadDataDetail(res.data[0]);
      } catch (e) {
        // this.$loading(false);
        this.loadingCari = false;
        this.$toast("Gagal mengambil data");
      }
    },
    async loadDataDetail(res) {
      this.modalSiswaOpen = false;
      // this.$loading(true);

      // data biaya pendidikan
      const tmpData = res;
      const resBiayaPendidikan = await apiGet(
        "/v1/pembayaran-siswa/biaya-pendidikan?id_jurusan=" +
          tmpData.id_jurusan_2
      );
      tmpData.biaya_pendidikan = resBiayaPendidikan.data;

      // remodel biayaPendidikan
      let sumBiaya = 0;
      tmpData.biaya_pendidikan.forEach((item) => {
        sumBiaya += parseInt(item.total);
        item.sumBiaya = sumBiaya;
      });
      this.nominalLunas = sumBiaya;

      this.loadingText = "Mengambil history pembayaran...";
      const resHistory = await apiGet(
        `/v1/pembayaran-siswa/history?id_siswa=${tmpData.id}&no_daftar=${tmpData.no_daftar}`
      );
      tmpData.history_pembayaran = resHistory.data;

      // count total bayar to set status tahap
      this.totalBayar = 0;
      tmpData.history_pembayaran.forEach((item) => {
        if (item.status == "VALIDASI") {
          this.totalBayar += parseInt(item.jumlah_fix);
        }
      });

      tmpData.biaya_pendidikan.forEach((item) => {
        if (this.totalBayar >= item.sumBiaya) {
          item.status = "LUNAS";
        }
      });

      // kurang bayar =
      tmpData.kurangBayar =
        this.totalBayar - tmpData.biaya_pendidikan[3].sumBiaya;

      if (this.$route.params.id > 0) {
        const res = await apiGet(
          `/v1/pembayaran-siswa?id=${this.$route.params.id}`
        );
        const data = res.data;

        tmpData.id_pembayaran = data.id;
        tmpData.tgl_bayar = data.tgl_bayar;
        tmpData.jumlah = data.jumlah;
        tmpData.jumlah_fix = data.jumlah_fix;
        tmpData.keterangan = data.keterangan;
        tmpData.bukti = data.bukti;
        tmpData.status = data.status;
        tmpData.alasan_ditolak = data.alasan_ditolak;

        this.dateRange = {
          startDate: data.tgl_bayar,
          endDate: data.tgl_bayar,
        };
      }

      tmpData.potongan_wisuda = 0;
      tmpData.potongan = 0;

      const perkiraanBayar = this.totalBayar + parseInt(tmpData.jumlah_fix);
      if (perkiraanBayar >= parseInt(this.nominalLunas)) {
      }

      // update no_daftar
      this.noDaftar = tmpData.no_daftar + tmpData.prefix_daftar;

      this.data = tmpData;
      this.loadingCari = false;
      // this.$loading(false);

      this.calculateFMI();
    },
    async load() {
      this.loadingCari = true;
      const res = await apiGet(
        "/v1/siswa?id=" +
          this.$route.query.id_siswa
      );

      await this.loadDataDetail(res.data);
    },
    objectToFormData(obj) {
      const formData = new FormData();

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          formData.append(key, obj[key]);
        }
      }
      return formData;
    },
    async save() {
      if (!this.data.jumlah || !this.data.jumlah_fix || !this.data.keterangan) {
        this.$toast("Form harus dilengkapi");
        return;
      }

      if (!this.selectedFile && this.$route.params.id < 0) {
        this.$toast("Bukti pembayaran harus dipilih");
        return;
      }

      this.data.id_pembayaran = this.$route.params.id;
      this.data.tgl_bayar = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );

      this.data.lebih_bayar = 0;
      if (parseInt(this.data.jumlah_fix) > parseInt(this.data.kurangBayar)) {
        // ini kurang bayarnya udah negatif, jadi kalkulasinya jadi positif
        this.data.lebih_bayar =
          parseInt(this.data.jumlah_fix) + parseInt(this.data.kurangBayar);
      }

      let formData = this.objectToFormData(this.data);
      formData.append("file", this.selectedFile);

      this.$refs.btn_simpan.disabled = true;

      const res = await apiUpload(`/v1/pembayaran-siswa`, formData);

      if (res.valid) {
        this.$toast("Berhasil disimpan");
        this.$router.back();
      }
    },
    updateJumlahFix() {
      const addValue = this.data.jumlah.toString().slice(-4);

      const jumlah = parseInt(this.data.jumlah.toString().replace(/\./g, ""));
      const potonganWisuda = parseInt(
        (this.data.potongan_wisuda || '0').replace(/\./g, "")
      );

      const jumlahFix = jumlah - potonganWisuda - parseInt(addValue);

      this.data.jumlah_fix = jumlahFix;

      this.calculateFMI();
    },
    async validasi() {
      if (confirm("Validasi Pembayaran?")) {
        this.loadingValidasi = true;
        const res = await apiPost("/v1/pembayaran-siswa/validasi", {
          id: this.data.id_pembayaran,
          id_siswa: this.data.id,
          nominal_lunas: this.nominalLunas,

          jumlah_fmi: parseInt(
            (this.data.jumlah_fmi.toString() || "0").replace(/\./g, "")
          ),
          potongan_wisuda: parseInt(
            (this.data.potongan_wisuda.toString() || "0").replace(/\./g, "")
          ),
          potongan: parseInt(
            (this.data.potongan.toString() || "0").replace(/\./g, "")
          ),
          jumlah_fmi_diterima: parseInt(
            (this.data.jumlah_fmi_diterima.toString() || "0").replace(/\./g, "")
          ),
        });

        this.loadingValidasi = false;

        if (res.valid) {
          if (res.no_induk) {
            this.data.no_induk = res.no_induk;
          }
          if (res.no_lunas) {
            this.data.no_lunas = res.no_lunas;
          }

          this.$toast("Berhasil divalidasi");
          this.modalOpen = false;
          // this.$router.back();
        } else {
          this.$toast("Validasi Gagal");
        }
      }
    },
    async inputAlasan() {
      const alasan = prompt("Input alasan ditolak");

      if (!alasan) {
        return;
      }

      const res = await apiPost("/v1/pembayaran-siswa/tolak-validasi", {
        id: this.data.id_pembayaran,
        alasan,
      });

      if (res.valid) {
        this.$toast("Pembayaran ditolak");
        this.$router.back();
      }
    },
    calculateFMI() {
      this.data.jumlah_fmi = ((15 / 100) * this.data.jumlah_fix)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      const potongan = parseInt(this.data.potongan.toString().replace(".", ""));
      const fmiKotor = parseInt(
        (this.data.jumlah_fmi || "0").replace(/\./g, "")
      );

      this.data.jumlah_fmi_diterima = (fmiKotor - potongan)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "pembayaran-siswa");

    setTimeout(() => {
      this.$refs["input_nodaftar"].focus();
    }, 150);

    if (this.data.id > 0) {
      this.load();
    }
  },
};
</script>

<style lang="scss" scoped>
.pengajuan-form {
  h1 {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
    height: calc(100vh - 160px);
    overflow: auto;
    &.active {
      width: 1100px;
    }

    .table-inline {
      input,
      textarea {
        width: calc(100% - 22px);
      }

      select {
        width: 100%;
      }

      .checkbox {
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;
        input {
          width: 20px;
          height: 20px;
        }
      }

      table {
        width: 100%;
        tr {
          td {
            &:nth-child(1) {
              width: 100px;
            }
            &:nth-child(2) {
              width: 10px;
            }

            &.btn-group {
              display: flex;
              gap: 10px;
            }
          }
        }
      }

      button {
        width: 150px;
      }
    }
  }

  .table-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: 0;
    }

    &.inactive {
      opacity: 0.5;
    }

    p {
      color: var(--blue);
    }
  }

  tr.lunas {
    color: green;
    font-weight: bold;
  }

  .lihat-bukti {
    display: block;
    color: var(--blue);
    padding: 10px 0;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .modal-body {
    max-width: 700px;
    display: grid;
    grid-template-rows: 89% 10%;

    .top {
      overflow-y: auto;
    }
    .footer {
      display: flex;
      padding: 10px 0;
      align-items: center;
      justify-content: space-between;
      > div {
        display: flex;
        gap: 10px;
        margin-top: 20px;
      }
    }

    &.modal-siswa {
      display: block;
      h2 {
        margin: 0;
        padding: 0;
      }
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          border-bottom: 1px solid #ccc;
          padding: 10px 0;
          cursor: pointer;
          p {
            margin: 0;
          }
          &:hover {
            background-color: #f7f7f7;
          }
        }
      }
    }
  }

  .badge {
    background: var(--green);
    color: #fff;
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 4px;
  }

  .kurang {
    color: #f00;
  }

  @keyframes blink {
    0% {
      background-color: orange;
    }
    100% {
      background-color: #fff;
    }
  }

  .blinking-element {
    // animation: blink 0.5s infinite;
  }
}

.loading {
  flex-direction: column;
}

.total-bayar {
  background: green;
  color: #fff;
  height: auto;
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 5px;
}
.loading {
  background: rgba(255,255,255,0.5);
}
</style>
<style>
.daterangepicker.openscenter {
  right: unset !important;
}
</style>